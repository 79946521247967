import React from "react";
import "../css/SignInAndSignUp.css";
import SignInForm from "./SignInForm";

const SignIn = () => {
  return (
    <div className="sign-in-up-page">
      <SignInForm />
    </div>
  );
};

export default SignIn;
